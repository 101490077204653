import { getBackendSrv } from '@grafana/runtime';
import { DicoProps, getTranslation } from 'types/translation';
import { DashboardsProps, IsoClasses, IsoClassificationsProps, LengthUnit, ThresholdValues } from 'types/types';
import {
  ALERT_ROW_INDEX,
  isPanelWithLengthUnit,
  VIB_SEV_ALARM_PANEL_INDEX,
  VIB_SEV_TRIP_PANEL_INDEX,
  VIB_SEVERITY_ALERT_PANEL_ID,
  VIB_SEVERITY_TRIP_PANEL_ID,
} from './constants';
import { findIndexById } from './helpers';

/**
 * Get, find and inject ALERT params from previous dashboard.
 * Only consider to inject alert values if analysis profile is not changed
 * (otherwise alert values are set by default)
 */

export const getDashboardAlerts = async (
  dashboardToUpdate: DashboardsProps,
  newJson: any,
  previousDefaultVibSevValue: ThresholdValues,
  currentDefaultVibSevValue: ThresholdValues,
  isoClassifications: IsoClassificationsProps,
  currentClass: IsoClasses,
  customIso: string[]
) => {
  const { id, dashboardJson, previousLengthUnit, lengthUnit, lang } = dashboardToUpdate;
  const dico = getTranslation(lang, isoClassifications);

  const previousDashboardAlerts = await getBackendSrv()
    .get(`/api/alerts?dashboardId=${id}`)
    .catch((err: any) => {
      console.log(err);
      return;
    });

  if (!previousDashboardAlerts?.length) {
    console.log(`No alert for dashboard with id ${id}`);
    return;
  }

  if (dashboardToUpdate.profile !== dashboardToUpdate.newProfile) {
    return;
  }

  // first, check if alarm and trip values exist (vibration severity alerts)

  const isVibSevThresholds = checkIfVibrationSeverityHasDoubleAlert(dashboardJson, dico);

  // Inject alert thresholds in new json if needed.

  if (
    (!isVibSevThresholds.alarm || !isVibSevThresholds.trip) &&
    dashboardToUpdate.profile?.toUpperCase() !== dico.valve?.toUpperCase()
  ) {
    // ALARM
    newJson.dashboard.panels[ALERT_ROW_INDEX].panels[
      VIB_SEV_ALARM_PANEL_INDEX
    ].alert.conditions[0].evaluator.params[0] = currentDefaultVibSevValue.alarm;
    // TRIP
    newJson.dashboard.panels[ALERT_ROW_INDEX].panels[VIB_SEV_TRIP_PANEL_INDEX].alert.conditions[0].evaluator.params[0] =
      currentDefaultVibSevValue.trip;
    // need to inject channel notification in the new alert Vibration Severity (trip)
    if (!isVibSevThresholds.trip) {
      const indexVibrationOldAlert = previousDashboardAlerts.findIndex((alert: { name: string }) =>
        alert.name?.toUpperCase()?.includes(dico.vibratorySeverity?.toUpperCase())
      );
      if (indexVibrationOldAlert !== -1) {
        const alertPanelId = previousDashboardAlerts[indexVibrationOldAlert].panelId;
        const indexInOldDashboard = dashboardJson.dashboard.panels.findIndex(
          (panel: { id: number }) => panel.id === alertPanelId
        );
        if (indexInOldDashboard !== -1) {
          newJson.dashboard.panels[ALERT_ROW_INDEX].panels[VIB_SEV_TRIP_PANEL_INDEX].alert.notifications =
            dashboardJson.dashboard.panels[indexInOldDashboard].alert.notifications;
        } else {
          for (const panelRow of dashboardJson.dashboard.panels) {
            if (panelRow?.type === 'row' && panelRow?.panels) {
              const indexPanelRowOldDashboard = panelRow.panels.findIndex(
                (panel: { id: number }) => panel.id === alertPanelId
              );
              if (indexPanelRowOldDashboard !== -1) {
                newJson.dashboard.panels[ALERT_ROW_INDEX].panels[VIB_SEV_TRIP_PANEL_INDEX].alert.notifications =
                  panelRow.panels[indexPanelRowOldDashboard].alert.notifications;
              }
            }
          }
        }
      }
    }
  }

  for (const alert of previousDashboardAlerts) {
    const alertIndex = findIndexById(dashboardJson.dashboard.panels, alert.panelId);

    if (alertIndex !== -1 && dashboardToUpdate.profile === dashboardToUpdate.newProfile) {
      const alertParams = dashboardJson.dashboard.panels[alertIndex].alert;

      for (const [newAlertPanelIndex, newAlertPanel] of newJson.dashboard.panels.entries()) {
        // as all the rows of the dashboard model are collasped, we have to find the panel id in panels[indexPanel].panels
        // search alert in row panels
        if (newAlertPanel.type === 'row' && newAlertPanel.panels) {
          const newAlertPanelRowIndex = findIndexById(newAlertPanel.panels, alert.panelId);

          // Inject old alert params in the new dashboard model
          if (newAlertPanelRowIndex !== -1) {
            // Valve profile
            if (
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].title?.toUpperCase() ===
              dico.valveState?.toUpperCase()
            ) {
              // only modify the 'for' parameter as it's the only value that can be customized
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.for = alertParams.for;
            }

            if (
              alertParams.name?.toUpperCase() !== dico.vibratorySeverity?.toUpperCase() && // exists only in the valve profile JSON (not customisable)
              alertParams.name?.toUpperCase() !== dico.battery?.toUpperCase() && // no custom params allowed
              alertParams.name?.toUpperCase() !== dico.valveState?.toUpperCase() && // just one parameter is customisable (done above)
              alertParams.name?.toUpperCase() !== dico.anomalyScore?.toUpperCase()
            ) {
              // other profiles
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert = alertParams;
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.frequency = '5m';
            } else {
              // notifications
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.notifications =
                alertParams.notifications;
              // alert message
              if (alertParams?.message && alertParams.name?.toUpperCase() !== dico.valveState?.toUpperCase()) {
                newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.message =
                  alertParams?.message;
              }
              // alert tags
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.alertRuleTags =
                alertParams?.alertRuleTags;
            }
            // Check if another query has been added (trend for instance). Compare previous targets length (array with all the queries) with the new ones.
            // If not equal, add all the targets to the new json. Need to do so because we copy all param alerts and it can happen that
            // an alert is set on a query that has been added by user.
            if (
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].targets.length !==
                dashboardJson.dashboard.panels[alertIndex].targets.length &&
              alertParams.name?.toUpperCase() !== dico.anomalyScore?.toUpperCase()
            ) {
              newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].targets =
                dashboardJson.dashboard.panels[alertIndex].targets;
            }
            // convert alert value if unit has changed
            if (previousLengthUnit && previousLengthUnit !== lengthUnit && isPanelWithLengthUnit(alert.panelId)) {
              alertParams.conditions.map((condition: { evaluator: { params: any[] } }, conditionIndex: number) => {
                let newMeterAlertValue = Number((condition.evaluator.params[0] * 25.4).toFixed(1));
                let newInchAlertValue = Number((condition.evaluator.params[0] / 25.4).toFixed(2));
                // if alert value is equal to default value (depending on power class), just assign converted threshold default value,
                // otherwise make the conversion (above).
                // VIB_SEVERITY_ALERT_PANEL_ID = alarm panel id
                if (
                  (alert.panelId === VIB_SEVERITY_ALERT_PANEL_ID || alert.panelId === VIB_SEVERITY_TRIP_PANEL_ID) &&
                  condition.evaluator.params[0] === previousDefaultVibSevValue
                ) {
                  if (alert.panelId === VIB_SEVERITY_ALERT_PANEL_ID) {
                    newMeterAlertValue =
                      dashboardToUpdate.profile?.toUpperCase() === dico.valve?.toUpperCase()
                        ? currentDefaultVibSevValue.trip
                        : currentDefaultVibSevValue.alarm;
                    newInchAlertValue =
                      dashboardToUpdate.profile?.toUpperCase() === dico.valve?.toUpperCase()
                        ? currentDefaultVibSevValue.trip
                        : currentDefaultVibSevValue.alarm;
                  }

                  if (alert.panelId === VIB_SEVERITY_TRIP_PANEL_ID) {
                    newMeterAlertValue = currentDefaultVibSevValue.trip;
                    newInchAlertValue = currentDefaultVibSevValue.trip;
                  }
                }
                const newAlertValue = lengthUnit === LengthUnit.inch ? newInchAlertValue : newMeterAlertValue;
                newJson.dashboard.panels[newAlertPanelIndex].panels[newAlertPanelRowIndex].alert.conditions[
                  conditionIndex
                ].evaluator.params[0] = newAlertValue;
              });
            }
          }
        }
      }
    } else {
      for (const [indexOldPanel, oldPanel] of dashboardJson.dashboard.panels.entries()) {
        if (oldPanel.type === 'row' && oldPanel.panels) {
          const indexOldPanelRow = findIndexById(oldPanel.panels, alert.panelId);

          if (indexOldPanelRow !== -1) {
            const alertParamsPanelRow = dashboardJson.dashboard.panels[indexOldPanel].panels[indexOldPanelRow].alert;

            for (const [panelNewJsonIdx, panelNewJson] of newJson.dashboard.panels.entries()) {
              if (panelNewJson.type === 'row' && panelNewJson.panels) {
                const alertIndex = findIndexById(panelNewJson.panels, alert.panelId);

                if (alertIndex !== -1) {
                  if (
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex]?.title?.toUpperCase() ===
                    dico.valveState?.toUpperCase()
                  ) {
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.for = alertParamsPanelRow.for;
                  }

                  if (
                    alertParamsPanelRow?.name?.toUpperCase() !== dico.valveState?.toUpperCase() && // just one parameter is customisable (done above)
                    alertParamsPanelRow?.name?.toUpperCase() !== dico.battery?.toUpperCase() && // no custom params allowed
                    alertParamsPanelRow?.name?.toUpperCase() !== dico.anomalyScore?.toUpperCase() &&
                    alertParamsPanelRow.name?.toUpperCase() !== dico.vibratorySeverity?.toUpperCase()
                  ) {
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert = alertParamsPanelRow;
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.frequency = '5m';
                  } else {
                    // notifications
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.notifications =
                      alertParamsPanelRow.notifications;
                    // alert message (needs to be updated for valve dashboard)
                    if (
                      alertParamsPanelRow?.message &&
                      alertParamsPanelRow?.name?.toUpperCase() !== dico.valveState?.toUpperCase()
                    ) {
                      newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.message =
                        alertParamsPanelRow?.message;
                    }
                    // alert tags
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.alertRuleTags =
                      alertParamsPanelRow?.alertRuleTags;
                  }

                  // Check if another query has been added (trend for instance).
                  // If so, add all the targets to the new json
                  if (
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].targets.length !==
                      dashboardJson.dashboard.panels[indexOldPanel].panels[indexOldPanelRow].targets.length &&
                    alertParamsPanelRow?.name?.toUpperCase() !== dico.anomalyScore?.toUpperCase()
                  ) {
                    newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].targets =
                      dashboardJson.dashboard.panels[indexOldPanel].panels[indexOldPanelRow].targets;
                  }

                  if (previousLengthUnit && previousLengthUnit !== lengthUnit && isPanelWithLengthUnit(alert.panelId)) {
                    alertParamsPanelRow.conditions.map(
                      (condition: { evaluator: { params: any[] } }, conditionIndex: number) => {
                        let newMeterAlertValue = Number((condition.evaluator.params[0] * 25.4).toFixed(1));
                        let newInchAlertValue = Number((condition.evaluator.params[0] / 25.4).toFixed(2));
                        if (
                          (alert.panelId === VIB_SEVERITY_ALERT_PANEL_ID ||
                            alert.panelId === VIB_SEVERITY_TRIP_PANEL_ID) &&
                          condition.evaluator.params[0] === previousDefaultVibSevValue
                        ) {
                          if (alert.panelId === VIB_SEVERITY_ALERT_PANEL_ID) {
                            newMeterAlertValue =
                              dashboardToUpdate.profile?.toUpperCase() === dico.valve?.toUpperCase()
                                ? currentDefaultVibSevValue.trip
                                : currentDefaultVibSevValue.alarm;
                            newInchAlertValue =
                              dashboardToUpdate.profile?.toUpperCase() === dico.valve?.toUpperCase()
                                ? currentDefaultVibSevValue.trip
                                : currentDefaultVibSevValue.alarm;
                          }

                          if (alert.panelId === VIB_SEVERITY_TRIP_PANEL_ID) {
                            newMeterAlertValue = currentDefaultVibSevValue.trip;
                            newInchAlertValue = currentDefaultVibSevValue.trip;
                          }
                        }
                        const newAlertValue = lengthUnit === LengthUnit.inch ? newInchAlertValue : newMeterAlertValue;
                        newJson.dashboard.panels[panelNewJsonIdx].panels[alertIndex].alert.conditions[
                          conditionIndex
                        ].evaluator.params[0] = newAlertValue;
                      }
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

const checkIfVibrationSeverityHasDoubleAlert = (dashboardJson: any, dico: DicoProps) => {
  let panelVibSevAlarmFound = false;
  let panelVibSevTripFound = false;

  for (const [, panel] of dashboardJson.dashboard.panels.entries()) {
    if (panel.title?.includes(dico.vibSeverityAlarm)) {
      panelVibSevAlarmFound = true;
      continue;
    }
    if (panel.title?.includes(dico.vibSeverityTrip)) {
      panelVibSevTripFound = true;
      continue;
    }

    if (panel.type === 'row' && panel?.panels) {
      const indexVibSevAlarm = panel.panels.findIndex((panelRow: { title: string }) =>
        panelRow.title?.includes(dico.vibSeverityAlarm)
      );
      const indexVibSevTrip = panel.panels.findIndex((panelRow: { title: string }) =>
        panelRow.title?.includes(dico.vibSeverityTrip)
      );

      if (indexVibSevAlarm !== -1) {
        panelVibSevAlarmFound = true;
        continue;
      }
      if (indexVibSevTrip !== -1) {
        panelVibSevTripFound = true;
        continue;
      }
    }
  }
  return { alarm: panelVibSevAlarmFound, trip: panelVibSevTripFound };
};
